import React from "react";
import * as colors from "../../themes/colors";

const Footer = (props: any) => {
  return (
    <div
      // className="fixed-bottom"
      className="text-center"
      style={{
        padding: 20,
        backgroundColor: colors.primaryBackground,
        color: colors.primaryText,
      }}
    >
      <span>Byte Education</span>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
      }}>
        <span style={{paddingRight: 20}}>copyright 2020</span>
        <span>all rights reserved</span>
        <span style={{paddingLeft: 20}}>terms and conditions</span>
      </div>
    </div>
  );
};

export { Footer };
