import "./App.css";

import React from "react";
import {
  FaBookOpen,
  FaGithubSquare,
  FaListUl,
  FaRegCheckCircle,
} from "react-icons/fa";
import { MdAttachMoney, MdComputer } from "react-icons/md";

import banner from "./assets/clear-banner.svg";
import { Footer, Header, List, Section, SubSection } from "./components";
import {
  primaryBackground,
  quaternaryBackground,
  secondaryText,
} from "./themes/colors";

function App() {
  return (
    <div>
      <div className="body">
        <Header />
        <div className="banner">
          <img src={banner} alt="banner" />
        </div>
        <Section
          id="mission"
          name="Mission Statement"
          backgroundColor={quaternaryBackground}
          textColor={secondaryText}
        >
          <p className="text-center">
            Here at{" "}
            <i>
              <b>Byte Education</b>
            </i>
            , we provide an affordable and easily accessible platform to help
            teach programming online. We do this by providing a number of
            services to aid others in their path of learning or teaching
            programming. These services include, but aren't limited to, creating
            simple programming related articles, supplying simple programming
            lessons, and an online classroom environment to use to host
            programming projects and exercises.
          </p>
        </Section>
        <Section
          id="core-values"
          name="Core Values"
          backgroundColor={primaryBackground}
        >
          <div className="core-values">
            <div className="value">
              <FaRegCheckCircle size={100} />
              <p className="text-center value-label">Simple</p>
            </div>
            <div className="value">
              <MdAttachMoney size={100} />
              <p className="text-center value-label">Affordable</p>
            </div>
            <div className="value">
              <MdComputer size={100} />
              <p className="text-center value-label">Convenient</p>
            </div>
          </div>
        </Section>
        <Section
          id="services"
          name="Services"
          backgroundColor={primaryBackground}
        >
          <div className="services">
            <div className="value">
              <FaBookOpen size={100}></FaBookOpen>
              <p className="text-center service-label">Articles</p>
            </div>
            <div className="value">
              <FaListUl size={100} />
              <p className="text-center service-label">Programming Lessons</p>
            </div>
            <div className="value">
              <FaGithubSquare size={100} />
              <p className="text-center service-label">GitHub Classroom</p>
            </div>
          </div>
        </Section>
        <Section
          id="articles"
          name="Articles"
          backgroundColor={quaternaryBackground}
          textColor={secondaryText}
        >
          <p>
            To those who are more curious about general programming topics but
            don't care as much for the actual programming part, we have a
            section catered towards articles on topics related to programming.
            These articles don't require a base understanding in programming,
            but serve to simply educate others on programming related topics.
          </p>
        </Section>

        <Section
          id="lessons"
          name="Lessons"
          backgroundColor={primaryBackground}
        >
          <SubSection>
            <h3>Programming Languages</h3>
            <div className="row text-center">
              <div className="col-md">
                <p>Completed</p>
                <ul>
                  <li>JavaScript</li>
                </ul>
              </div>
              <div className="col-md">
                <p>In Progress</p>
                <ul>
                  <li>Python</li>
                </ul>
              </div>
              <div className="col-md">
                <p>Planned</p>
                <ul>
                  <li>Java</li>
                  <li>C</li>
                  <li>C++</li>
                  <li>C#</li>
                </ul>
              </div>
            </div>
          </SubSection>
          <br />
          <SubSection>
            <h3>Projects</h3>
            <div className="row text-center">
              <div className="col-md">
                <p>Completed</p>
                <ul>
                  <li>Front End Web Development</li>
                </ul>
              </div>
              <div className="col-md">
                <p>In Progress</p>
                <ul>
                  <li>React Web Application Development</li>
                </ul>
              </div>
              <div className="col-md">
                <p>Planned</p>
                <ul>
                  <li>React Native Application Development</li>
                  <li>Topics in Computer Science</li>
                </ul>
              </div>
            </div>
          </SubSection>
        </Section>
        <Section
          id="classroom"
          name="Classroom"
          backgroundColor={quaternaryBackground}
          textColor={secondaryText}
        >
          <p>
            We offer access to a GitHub Classroom under Byte Education that
            allows students to gain extra practice with feedback from our
            instructors. Within this classroom, we offer practice projects to
            supplement the content from our lessons. These projects differ in
            general difficulty, where some projects are much easier than others.
            We offer students these projects with varying difficulty to allow
            students to think critically and come up with their own solutions in
            projects that vary in purpose. Some projects provided serve as a
            practice solving a real world programming problem, some projects
            serve as a simple fun exercise, and some projects build onto
            programming concepts allowing students to better learn these
            concepts through coding them.
          </p>
          <p>
            As more lessons are created, more classrooms will be created with
            more projects to be assigned.
          </p>
          <SubSection>
            <h3>Project Types</h3>
            <p>
              As listed above, we have different types of projects. Below, there
              is an non-extensive list of categories that projects may fall
              under with some examples nested under.
            </p>
            Types:
            <ul>
              <List name="Simple Application of Knowledge">
                <li>Mathematical Functions</li>
                <li>String Functions</li>
              </List>

              <List name="Console based Games">
                <li>TicTacToe</li>
                <li>BlackJack</li>
              </List>
              <List name="Advanced Programming Concepts">
                <li>Run Times</li>
                <li>Algorithms</li>
                <li>Data Structures</li>
              </List>

              <List name="Real Life Examples / Application">
                <li>Password Validator</li>
                <li>Login / Authentication System</li>
                <li>Grocery Store System</li>
                <li>To Do List System</li>
              </List>
            </ul>
          </SubSection>
          <SubSection>
            <h3>Classrooms</h3>
            <span>Below are a list of our current classrooms:</span>
            <ul>
              <li>Python</li>
              <li>Java</li>
              <li>JavaScript</li>
              <li>Front End Web Development</li>
            </ul>
          </SubSection>
        </Section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
