import React from "react";
import "../../App.css";

const Section = ({ id, name, children, textColor, backgroundColor }: any) => {
  return (
    <div style={{ backgroundColor: backgroundColor != null ? backgroundColor : "transparent" }}>
      <div
        className="container section"
        style={{ paddingBottom: 20, paddingTop: 20,
          color: textColor != null ? textColor : "#f8f8f8"
        }}
        id={id}
      >
        <h1 className="text-center">{name}</h1>
        {children}
      </div>
    </div>
  );
};

const SubSection = (props: any) => {
  return <div>{props.children}</div>;
};

export { Section };
export { SubSection };
