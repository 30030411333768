const primaryBackground = "#3d6479";
const secondaryBackground = "#3d4679";
const ternaryBackground = "#3d7970";
const quaternaryBackground = "#f8f8f8";

const primaryText = "#f8f8f8";
const secondaryText = "#000";

export {
  primaryBackground,
  secondaryBackground,
  ternaryBackground,
  quaternaryBackground,
  primaryText,
  secondaryText,
};
