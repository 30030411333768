import React, { Fragment, useState } from "react";

const List = ({ name, children }: any) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  const showChildren = () => {
    if (show) {
      return <ul>{children}</ul>;
    } else {
      return <Fragment />;
    }
  };
  return (
    <Fragment>
      <li onClick={toggle}>{name}</li>
      {showChildren()}
    </Fragment>
  );
};

export { List };
