import React, { useState } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";

import logo from "../../menu-logo.svg";

const Header = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar className="header" dark expand="md">
        <NavbarBrand href="/">
          <img src={logo} alt="logo" width={50}></img> Byte Education
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#mission">Mission</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#core-values">Core Values</NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Services
                </DropdownToggle>
                <DropdownMenu right className="header-dropdown">
                  <DropdownItem>
                    <NavLink href="#services">Info</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#articles">Articles</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#lessons">Lessons</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#classroom">GitHub Classroom</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            {/*<UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/}
          </Nav>
          {/*<NavbarText>Simple Text</NavbarText>*/}
        </Collapse>
      </Navbar>
    </div>
  );
};

export { Header };
